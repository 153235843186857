.skeleton-loader {
  display: flex;
  flex-direction: column;
}

.skeleton-item {
  background-color: #f0f0f0;
  margin-bottom: 10px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
