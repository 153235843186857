/*iframe {
  pointer-events: none;
}*/

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
}
.google-autocomplete-input [type="text"]:focus {
  --tw-ring-color: transparent !important;
}

.site-sidabar {
  width: 340px;
}

.logo-bar {
  margin-bottom: 30px;
}

.logo-sidebar-container {
  @apply w-full pl-10 lg:pl-0 border-r border-white border-opacity-30;
  @apply lg:flex lg:items-center lg:justify-center;
}

.logo-sidebar-header {
  width: 173px !important;
  height: 46px !important;
}

.logo-bar svg {
  width: 189px;
}

.main-wrapper {
  width: 100%;
  max-width: 100%;
}

.sidebar-link {
  padding-left: 40px;
  @apply py-4 text-lg font-medium;
}

.sidebar-link:hover svg path,
.sidebar-link.is-active svg path {
  fill: #0c6ed1;
}

.sidebar-icon-bg {
  width: 35px;
  height: 35px;
}

.text-decoration {
  text-decoration-color: #ec5627 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 20px !important;
}

.flex-middle {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.site-header {
  @apply px-4 3md:px-24 lg:px-37;
}

.site-footer {
  @apply px-4 py-8 md:py-0 3md:px-24 lg:px-37;
}

/* site header */
.site-header-input-mobile {
  @apply relative w-full;
}

.site-header-input {
  @apply relative hidden 2md:block 2md:w-96 3md:w-400 lg:w-500 xl:w-600;
}

.site-header-input input::placeholder,
.site-header-input-mobile input::placeholder {
  @apply text-white;
}

.site-header-input input,
.site-header-input-mobile input {
  @apply w-full py-2 bg-lightPrimary text-white rounded-md;
  padding-left: 52px !important;
  border-width: 0px !important;
}

.site-header-input input:focus-visible,
.site-header-input-mobile input:focus-visible {
  outline: none !important;
  border-width: 0px !important;
}
/* alt */
.site-header-input-alt-mobile {
  @apply relative w-full;
}

.site-header-input-alt {
  @apply relative hidden 2md:block 2md:w-96 3md:w-400 lg:w-500 xl:w-600;
}

.site-header-input-alt input::placeholder,
.site-header-input-alt-mobile input::placeholder {
  color: #c9c9c9 !important;
}

.site-header-input-alt input,
.site-header-input-alt-mobile input {
  @apply w-full py-2 bg-white text-lightBlack rounded-md;
  padding-left: 52px !important;
  border-width: 0px !important;
}

.site-header-input-alt input:focus-visible,
.site-header-input-alt-mobile input:focus-visible {
  outline: none !important;
  border-width: 0px !important;
}
/* end: site header */

/* input search*/
.input-search {
  @apply relative rounded-md bg-white 2md:block 2md:w-96 3md:w-400 lg:w-500 xl:w-600;
}

.input-search input::placeholder {
  color: #aaaaaa !important;
}

.input-search input {
  @apply w-full py-0.8 bg-white text-lightBlack rounded-md;
  padding-left: 52px !important;
  border-width: 0px !important;
}

.input-search input:focus-visible {
  outline: none !important;
  border-width: 0px !important;
}
/* end: input search*/

/* tag search */
.tag-search-input {
  @apply relative w-full;
}

.tag-search-input input::placeholder {
  @apply text-lightBlack;
}

.tag-search-input input {
  @apply w-full py-2 bg-footerGray text-lightBlack rounded-md;
  padding-left: 52px !important;
  border-width: 0px !important;
}

.tag-search-input input:focus-visible {
  outline: none !important;
  border-width: 0px !important;
}
/* end: tag search */

.tag-div-min-height {
  min-height: 40px !important;
}
/* start: others input */
.others-input {
  @apply relative 3sm:w-50;
}

.others-input input::placeholder {
  @apply text-lightBlack;
}

.others-input input {
  @apply w-full bg-transparent text-lightBlack border-gray text-sm;
  padding-left: 5px;
  border-width: 0px 0px 1px 0px !important;
}

.others-input input:focus-visible {
  outline: none !important;
  border-width: 0px 0px 1px 0px !important;
}
/* end: others input */
.site-header .profile-button {
  @apply rounded-md w-10 h-10;
}

.profile-name .profile-name-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}

.alert {
  @apply bg-lightBlack;
  @apply justify-between;
  @apply flex;
  @apply items-center;
  @apply gap-4;
  @apply text-white;
  padding: 17px 22px;
}

.close {
  @apply w-5 h-5;
  cursor: pointer;
  content: url("./assets/svg/close.svg");
}

.close-video {
  cursor: pointer;
  content: url("./assets/svg/close-video.svg");
  width: 12px;
  height: 12px;
}

.drop-shadow-2 {
  filter: drop-shadow(0px 16px 32px rgba(35, 23, 5, 0.26)) !important;
}

.bg-size-full {
  background-size: 100% 100%;
}

.icon-container-stats {
  width: 48px !important;
  height: 48px !important;
  background: #f0f0f0 !important;
  @apply rounded-full flex justify-center items-center;
}
.notification-panel {
  min-width: 500px;
  height: 100vh;
  padding: 32px 50px;
}

.transaction-panel {
  min-width: 686px;
  height: 100vh;
  background: #f8f8fb;
  border: 1px solid #ececf5;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.account-panel {
  min-width: 500px;
  height: 100vh;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.account-avatar {
  width: 70px !important;
  height: 70px !important;
  @apply rounded-full;
}

.transaction-header {
  height: 98px !important;
  @apply px-8 pl-9 pr-3;
}

.transaction-content {
  background: #fffff !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.07) !important;
  @apply p-10;
}

.list-style {
  list-style: none !important;
}

.list-style li:before {
  content: "✓";
  padding-right: 10px;
  color: #4ed988;
}

.bg-footer-transaction {
  @apply px-10 py-3;
  background: rgba(232, 232, 232, 0.5) !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: white;
}

.notification-dropbox {
  @apply bg-notification-dropbox border-dotted border-notification-border-gray border-2 w-full rounded-md flex items-center justify-center;
  height: 140px;
}

input[type="file"] {
  display: none;
}

.tracking-very-tight {
  letter-spacing: -0.03em !important;
}

.text-10 {
  font-size: 11px !important;
  line-height: 156% !important;
}

.logo-invoice {
  width: 150px;
  height: 40px;
}

/*modal-animation*/
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.modal-animation {
  animation: appear 350ms ease-in 1;
}

/* tab animation */
.tab-animation {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  border-radius: 4px 4px 0px 0px;
}

.tab-animation:before,
.tab-animation:after {
  height: 4px;
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 0;
  border-radius: 4px 4px 0px 0px;
  @apply bg-primary;
}
.tab-animation:before {
  border-radius: 4px 4px 0px 0px;
  bottom: 0;
  right: 0;
}
.tab-animation:after {
  border-radius: 4px 4px 0px 0px;
  bottom: -4px;
  left: 0;
}

.tab-animation:hover:after,
.tab-animation.active:after {
  border-radius: 4px 4px 0px 0px;
  width: 100%;
}
/* end tab animation */

/* menu */
.menu {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  border-radius: 4px 4px 0px 0px;
}

.menu:before,
.menu:after {
  height: 100%;
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 0;
  border-radius: 4px 4px 0px 0px;
  @apply bg-primary;
}
.menu:before {
  border-radius: 4px 4px 0px 0px;
  bottom: 0;
  right: 0;
}
.menu:after {
  border-radius: 4px 4px 0px 0px;
  bottom: 0;
  left: 0;
}

.menu:hover:after,
.menu.active:after {
  border-radius: 4px 4px 0px 0px;
  width: 4px;
}
/* end menu animation */

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px !important;
}
/* end: alter css cropper */

.popup-content {
  z-index: 1001 !important;
}


/* notification CSS */
.notification-container {
  background: #ffffff;
  /* Drop Shadow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.flex-group-checkbox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.grid-row-auto {
  grid-template-rows: auto 1fr auto !important;
}


/* alter css cropper */
.cropper-view-box {
  outline: 1px dashed #fff !important;
  outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-line {
  background-color: #fff !important;
}

.cropper-point {
  background-color: #fff !important;
}

.cropper-point.point-se::before {
  background-color: #fff !important;
}

.cropper-container {
  border-radius: 6px !important;
}