@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-Regular.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font/Inter-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

body {
  margin: 0;
  font-family: "Inter";
  @apply bg-siteBg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  color: #0c6ed1;
  outline: none;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

input[type="checkbox"]:focus {
  outline: none !important;
  box-shadow: none;
}

input[type="text"],
input[type="password"],
input#react-select-address-input,
input[type="email"] {
  @apply rounded-md pb-1 pt-5 px-4
    2xl:pt-8 text-lightBlack;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

.input-border{
  @apply rounded-md pb-1 pt-5 px-4
    2xl:pt-8 text-lightBlack;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
#react-select-3-input::placeholder,
input[type="email"]::placeholder,
textarea[type="text"]::placeholder {
  color: #aaaaaa !important;
}

.place-holder input[type="text"]::placeholder {
  color: rgb(8, 8, 8) !important;
} 

textarea[type="text"] {
  @apply rounded-md text-lightBlack;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input#react-select-address-input {
  @apply text-lightBlack;
  width: 100% !important;
  box-shadow: none;
}

input:focus-visible,
textarea:focus-visible,
.react-select-address-input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

input:focus,
textarea:focus,
.react-select-address-input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

textarea,
input,
input#react-select-address-input {
  outline: none;
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  /* @apply transform scale-75 -translate-y-6; */
}

input[type="text"]:focus-within ~ label,
input[type="password"]:focus-within ~ label,
input[type="email"]:focus-within ~ label,
textarea:focus-within ~ label,
input#react-select-address-input:focus-within ~ label {
  @apply text-secondary opacity-100;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.modal-scroll::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  z-index: 99999;
}
.modal-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  z-index: 99999;
}
.modal-scroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  z-index: 99999;
}

.mobile-scroll::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}
.mobile-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.mobile-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.color-select-label {
  color: #aaaaaa !important;
}

input[type="range"] {
  @apply rounded-full w-full h-1.5;
  -webkit-appearance: none;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #0c6ed1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  cursor: pointer; /* Cursor on hover */
}

iframe {
  pointer-events: all !important;
}

@media (max-width: 1164px) {
  .custom-scroll::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  .custom-scroll::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 5px;
  }

  .modal-scroll::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    z-index: 99999;
  }
  .modal-scroll::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    z-index: 99999;
  }
  .modal-scroll::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 5px;
    z-index: 99999;
  }
}

.lineConnector::before{
  content: "";
  height: 2.45rem;
  width: .52rem;
  border-bottom-left-radius: 0.375rem;
  /* background-color: red; */
  position: absolute;
  left: 0.75rem;
  top: -0.5rem;
  border-left: solid 1px rgba(0,0,0,0.2);
  border-bottom: solid 1px rgba(0,0,0,0.2);
  @apply 4sm:hidden;
}

@import "./assets/css/index.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
