@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.custom-style {
  background-color: transparent !important;
}

.wrapper {
  position: relative !important;
  padding-top: 56.25% !important;
}

.video-wrapper {
  position: relative;
  padding-top: 56.25%;
}

@media (orientation: landscape) {
  .video-wrapper.mobile {
    position: relative;
    height: calc(100svh - 30px);
    padding-top: 0;
  }
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slick-slide > div {
  display: grid;
  place-items: center;
  margin-top: 50px;
  margin: auto;
  /* height: 555px; */
  height: 100%;
  padding: 0px;
  background: #000000;
  
}

/*** audio player ***/

.audio-player {
  border-radius: 6px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
}

input[type="range"] {
  overflow: hidden;
  -webkit-appearance: none;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  background: transparent !important;
}

/* for Firebox */
input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: transparent !important;
}

/* for IE */
input[type="range"]::-ms-thumb {
  cursor: pointer;
  background: transparent !important;
}
