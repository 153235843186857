/* wilson */
.account-img {
  width: 30px !important;
  height: 30px !important;
}

/* 
Custom CSS for react-datepicker by HackerOne
Original Author: 	msnegurski https://github.com/msnegurski
Edited and Added Features: Wilson Joseph Hinacay https://github.com/wilsonJosephHinacay
gray-300 - rgb(209 213 219);
gray-200 - rgb(229 231 235);
gray-800 - rgb(31 41 55);
gray-400 - rgb(156 163 175);
gray-700 - rgb(55 65 81);
blue-500 - rgb(59 130 246);
blue-200 - rgb(191 219 254);
*/
.react-datepicker__input-container input {
  @apply rounded-md px-4;
  @apply block w-full appearance-none outline-none bg-white text-base;
  border-color: #dfdfdf;
  border-width: 1px !important;
  padding-left: 1.2rem !important;
  padding-right: 2.4rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.react-datepicker-popper {
  @apply z-50 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded border-2 border-transparent hover:border-blue-500;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker--time-only {
  max-height: 300px !important;
  overflow: auto !important;
}

.react-datepicker__header--time--only {
  @apply text-base font-bold tracking-tighter;
}

.react-datepicker__time-list-item {
  @apply py-2 px-2.5 text-md tracking-tighter cursor-pointer hover:bg-primary hover:text-white;
}

.react-datepicker--time-only::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.react-datepicker--time-only::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.react-datepicker--time-only::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}
/* end CSS for react-datepicker */

/* home 1st */
.landing-first {
  background: url("./assets/images/landing-1.png");
  min-height: 839px !important;
  @apply w-full px-7.5 bg-cover bg-center;
}
.landing-heading {
  @apply text-5.5xl text-lightBlack font-bold leading-none;
  letter-spacing: -0.02em !important;
}
/* end home 1est */

/* home 2nd */
.landing-second {
  @apply w-full bg-white px-7.5 pt-7.5 md:pt-23 1md:pt-50 2md:pt-65 3md:pt-350;
  min-height: 350px !important;
}
/* end home 2nd */

/* home 3rd */
.landing-third {
  @apply w-full bg-landing;
  min-height: 100px !important;
}
/* end home 3rd */

/* home 4th */
.landing-fourth {
  background: url("./assets/images/pricing-banner.png");
  min-height: 577px !important;
  @apply w-full bg-lightBlack px-7.5 py-25 bg-cover bg-center text-center;
}

.thumbnail-video {
  background: url("./assets/images/thumbnail.png");
  @apply w-full grid rounded-xl bg-cover h-400 bg-center;
}
/* end home 4th */

/* breadcrumbs banner */
.breadcrumbs-banner {
  background: url("./assets/images/breadcrumbs-banner.png");
  min-height: 239px !important;
  @apply w-full bg-lightBlack py-15 px-7.5 bg-cover bg-center text-center text-white;
}
/* end breadcrumbs banner */

.social-box {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

/* wysiwyg editor*/
.ql-editor {
  min-height: 100px !important;
  width: 100%;
}

.ql-toolbar {
  border-left: 1px solid #e6e6e6 !important;
  border-top: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
  border-bottom: 0px solid #e6e6e6 !important;
  border-radius: 4px 4px 0px 0px !important;
  background: #ffffff !important;
}

.ql-bold .ql-stroke {
  stroke: #8c96b5 !important;
}

.ql-italic .ql-stroke {
  stroke: #8c96b5 !important;
}

.ql-underline .ql-stroke {
  stroke: #8c96b5 !important;
}

.ql-underline .ql-fill {
  fill: #8c96b5 !important;
}

.ql-formats .ql-list .ql-stroke {
  stroke: #8c96b5 !important;
}

/* active */

.ql-active .ql-stroke {
  stroke: #0c6ed1 !important;
}

.ql-active .ql-fill {
  stroke: #0c6ed1 !important;
}

.ql-list.ql-active .ql-stroke {
  stroke: #0c6ed1 !important;
}

/* end active */

.ql-container {
  border-left: 1px solid #e6e6e6 !important;
  border-bottom: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
  border-top: 0px solid #e6e6e6 !important;
  border-radius: 0px 0px 4px 4px !important;
}

/* matured content */
.matured-content-blured {
  bottom: auto;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(50px);
  /* @apply flex justify-center w-full h-full rounded-md text-white; */
  @apply flex justify-center w-full h-full text-white;
}

.matured-content-blured-small {
  bottom: auto;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  /* @apply flex justify-center w-full h-full rounded-md text-white; */
  @apply flex flex-col justify-center w-full h-full text-white;
}

/* advance search animation */
.advance-search {
  visibility: hidden;
  transition: visibility 1s, max-height 1s;
  max-height: 0;
  overflow: hidden;
}

.advance-search.open {
  visibility: visible;
  /* Set max-height to something bigger than the box could ever be */
  max-height: 700px;
}

/* messages input search */
.message-input-search {
  @apply relative w-full;
}

.message-input-search input::placeholder {
  @apply text-lightBlack;
}

.message-input-search input {
  @apply w-full py-2 bg-white text-lightBlack rounded-md;
  padding-left: 55px !important;
  border-width: 0px !important;
}

.message-input-search input:focus-visible {
  outline: none !important;
  border-width: 0px !important;
}

.message-active {
  @apply bg-primary bg-opacity-6;
}

/* messages input type */
.message-input {
  @apply relative w-full;
}

.message-input input::placeholder {
  color: #aaaaaa !important;
}

.message-input input {
  @apply w-full py-2 bg-white text-lightBlack rounded-md;
  padding-left: 26px !important;
  border-width: 1px !important;
  border-color: #dddddd !important;
}

.message-input input:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

/* message content */
.message-content {
  min-height: calc(100vh - 528px);
  max-height: calc(100vh - 528px);
  overflow: auto;
}

/* message content scroll */
.message-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.message-content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.message-content::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.message-content-file {
  max-width: 345px;
}

.message-content-item {
  @apply rounded-md py-3.5 px-4.5 break-normal;
  max-width: 50%;
}

@media (max-width: 880px) {
  .message-content-item {
    max-width: 346px;
  }
}

/* Logout Button */
.profile-dropdown {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.12));
}

.profile-hover:hover,
.profile-hover:hover .profile-name > p {
  background-color: #0861bb !important;
  color: white !important;
}

.btn-hover-white-primary:hover,
.btn-hover-white-primary:hover div {
  background-color: #0c6ed1 !important;
  color: white !important;
}

.custom-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 4px;
}

.tooltip-value {
  margin-top: 4px;
}

.upload-image-no-preview {
  width: 100%;
  height: 223px;
  /* reference : https://kovart.github.io/dashed-border-generator */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23DDDDDDFF' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
}

.resume-create {
  width: 100%;
  /* reference : https://kovart.github.io/dashed-border-generator */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23DDDDDDFF' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
}

.photos-videos-container {
  width: 100%;
  height: 223px;
  border-radius: 6px;
}

.load-more-hover:hover {
  @apply bg-primary text-white;
}

.load-more-hover:hover .load-more-text {
  @apply text-white;
}

/* profile 2560px width */
.container-max-width {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

/*this css is for detailed-third-view for background images*/
/*Adjust the width also*/

.bodyFit {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.image-fit {
  width: 100% !important;
  height: auto;
  background-size: contain !important;
  background-position: center !important;
}

@media (max-width: 2570px) {
  .image-fit {
    height: 600px !important;
  }
}
/* @media (min-width:1025px){ */
@media (max-width: 1800px) and (min-width: 1345px) {
  .image-fit {
    height: 600px !important;
  }
}
@media (max-width: 1344px) and (min-width: 1025px) {
  .image-fit {
    height: 528px !important;
  }
}
@media (max-width: 1024px) {
  .image-fit {
    height: 528px !important;
  }
}

/* resume builder */
.resume-heading-title {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
}

.resume-side-number {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* template hover effects */
.template-hover .hidden-template-hover {
  visibility: hidden;
}

.template-hover:hover .hidden-template-hover {
  visibility: visible;
}

.template-hover:hover + .template-hover-footer p {
  color: #0c6ed1 !important;
}

/* custom checkbox */
.checkbox-container-custom {
  display: flex !important;
  /* align-items: center !important; */
  position: relative !important;
  min-height: 22px !important;
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container-custom input[type="checkbox"] {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
  height: 22px !important;
  width: 22px !important;
  width: 100% !important;
  z-index: 1 !important;
}

.checkmark {
  height: 22px !important;
  width: 22px !important;
  background-color: #ffffff !important;
  /* border: 1px solid #dfdfdf !important; */
  /* border-radius: 5px !important; */
  position: relative !important;
  top: 2px;
}

.checkmark-v2 {
  height: 22px !important;
  width: 22px !important;
  background-color: #ffffff !important;
  /* border: 1px solid #dfdfdf !important; */
  border-radius: 6px !important;
  position: relative !important;
  top: 2px;
}

.checkbox-v2 .checkbox-text {
  margin-left: 0 !important;
}

.check-minus {
  height: 22px !important;
  width: 22px !important;
  background-color: #ffffff !important;
  /* border: 1px solid #dfdfdf !important; */
  border-radius: 5px !important;
  position: relative !important;
  top: 2px;
}

.checkbox-container-custom label {
  margin-left: 12px !important;
  width: calc(100% - 36px) !important;
}

.checkbox-container-custom input:checked ~ .checkmark {
  background-color: #2196f3 !important;
}

.checkbox-container-custom input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* .checkbox-container-custom input:checked ~ .checkmark-v2 {
  background-color: white !important;
} */

.checkbox-container-custom input:checked ~ .checkmark-v2:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid #0C6ED1;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* minus */
.checkbox-container-custom input:checked ~ .check-minus {
  background-color: #777777 !important;
}

.checkbox-container-custom input:checked ~ .check-minus:after {
  content: "";
  position: absolute;
  left: 3.5px;
  top: 8px;
  width: 12px;
  height: 4px;
  background-color: #fff;
  border: solid white;
  border-width: 1px 1px 1px 1px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* end custom checkbox */

/* mention description */

.mention-link {
  @apply text-primary;
}

.template-four-padding {
  padding: 22px 27px 17px 28px !important;
}

.template-four-padding-full {
  padding: 30px 37px 23px 38px !important;
}

.template-four-padding-skills {
  padding: 1.5px 4px 2px 4px !important;
}

.template-four-padding-skills-full {
  padding: 2px 5px 2.5px 5px !important;
}

.template-three-page-number {
  padding: 6px 5px 8px 5px !important; /* top right bottom left */
  border-radius: 0px 0px 2px 2px !important;
}

.font-template-three {
  font-family: "Playfair Display", serif !important;
}

.border-bottom-resume-three {
  border-bottom: 0.5px solid #464646 !important;
}

/* pop info box shadow custom css */
.pop-info-box-shadow {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12) !important;
}

.pop-info-hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.pop-info-show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

/* truncate multiline text */
.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blurred-img-lazy img {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.blurred-img-lazy {
  background-repeat: no-repeat;
  background-size: cover;
}

.blurred-img-lazy::before {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0;
  animation: pulse 2.5s infinite;
  background-color: white;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.blurred-img-lazy.loaded::before {
  animation: none;
  content: none;
}

.blurred-img-lazy.loaded img {
  opacity: 1;
}

/*Conflict in other select component */
/* .milestone__control{
  height: 60px !important;
}

.milestone__control label{
  margin-top : -7px !important
}

.milestone__indicators{
  margin-top: -19px !important;
}

.milestone__value-container{
  margin-top: -10px !important;
} */

@media (max-width: 1164px) {
  .milestone__control {
    min-height: 40 !important;
  }
  .milestone__placeholder {
    font-size: 12px !important;
  }
  .milestone__input-container {
    font-size: 12px !important;
  }
  .milestone__input {
    font-size: 12px !important;
  }
  .milestone__multiValue {
    font-size: 12px !important;
  }
  .milestone__menu {
    font-size: 12px !important;
  }
  .milestone__value-container {
    font-size: 12px !important;
  }

  .filter-container .milestone__input-container {
    font-size: 16px !important;
    line-height: 110%;
  }
  .filter-container .milestone__input {
    font-size: 16px !important;
    transform: scaleY(0.875);
  }

  .date-container .milestone__placeholder {
    font-size: 16px !important;
  }

  .date-container .milestone__input {
    font-size: 16px !important;
  }
  .date-container .milestone__single-value {
    font-size: 16px !important;
  }
}

/* responsive with */
.width-responsive-detailed-view-full {
  width: 100%;
}

.width-responsive-detailed-view {
  width: 100%;
}

.width-responsive-list-view {
  width: calc(100% - 90px);
}

.width-responsive-list-view-full {
  width: 100%;
}

.min-h-300 {
  min-height: 300px !important;
}


.milestone-checkbox-form{
  margin-top: -1em;
}

@media (max-width: 500px) {
  .milestone-checkbox-form{
    margin-top: 10px ;
  }

  .hero-button{
    padding: 8px 0px;
  }
  
  .hero-button img{
    height: 16px !important;
  }
  .btn-add-milestone{
    min-height: 40px !important;
    border-radius: 6px !important;
    padding: 0px 12px 0px 12px !important;
  }

  .milestone__control{
    margin-top: 6px;
    /* height: 40px !important; */
  }
  /* Added this directly in select component to avoid non inner label*/
  /* .milestone__value-container{
    margin-top: -23px !important;
  } */

  /* .milestone__indicators{
    margin-top: -29px !important;
  } */

  .date-container .milestone__control {
    margin-top: 0;
  }


  .resume-heading-title{
    font-size: 16px;
  }
  
}

@media (min-width: 515px) {
 

  .width-responsive-list-view {
    width: calc(100% - 120px);
  }
}

@media (min-width: 1024px) {
  .width-responsive-detailed-view {
    width: calc(100% - 435px);
  }
}

@media (min-width: 1165px) {
  .width-responsive-list-view {
    width: calc(100% - 200px);
  }
}

.custom-with-responsive {
  width: 75%;

  @media (max-width: 1570px) {
    width: 80%;
  }

  @media (max-width: 1470px) {
    width: 85%;
  }

  @media (max-width: 1386px) {
    width: 93%;
  }

  @media (max-width: 1268px) {
    width: 100%;
  }
}

.resume-builder-width-responsive {
  width: calc(100% - 323px);

  @media (max-width: 1165px) {
    width: 100% !important;
  }
}


.steps-resume-responsive {
  width: calc(100% - 58px);

  @media (max-width: 989px) {
    width: 100% !important;
  }
}

.sign-in-btn {
  padding-top: 0.75rem;
  padding-bottom: calc(0.75rem + 2px);
}

.upgrade-premium-header-lock {
  width: 16px;
  height: 20px;
}

.upgrade-premium-popup {
  /* USE 38px TO REPLICATE THE FIGMA DESIGN AS CLOSE AS POSSIBLE */
  /* top: 38px !important; */

  /* USE 45px TO MATCH THE TOP POSITION OF THE NOTIFICATION POPUP */
  top: 45px !important;

  /* USE 58px TO MATCH THE TOP POSITION OF THE PROFILE POPUP */
  /* top: 58px !important; */
}

.upgrade-premium-popup > div {
  border-radius: 6px 6px 0 0;
}

.header-message-icon > a {
  display: flex;
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
}

.signup-form.form-container form#create-account-component label:not([for=checbox-termsAndConditions])::before,
label.marked::before {
  content: '* ';
  color: #FC4F4F;
}

.flex-column {
  flex-direction: column;
}

.p-0-important {
  padding: 0 !important;
}

.min-w-5 {
  min-width: 1.25rem;
}

.break-name {
  word-break: break-word;
}

.fade-container {
  --mask: linear-gradient(to bottom, 
      rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
      rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;
  -webkit-mask: var(--mask); 
  mask: var(--mask);
}

.add-photo-dropzone {
  cursor: pointer;
}

.image-dropzone {
    display: flex;
    align-items: center;
    padding: 2.5rem 0;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 6px;
    border: 2px dashed var(--Colors-Light-Light-Gray, #E0E0E0);
    background: white;
}

.add-photo-thumbnail {
  width: fit-content;
  max-height: 72px;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
}

.add-photo-preview img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.add-photo-details__name {
  font-weight: 600;
}

.add-photo-details__size {
  font-weight: 400;
  font-size: 14px;
}

.custom-modal-footer-separator {
  bottom: calc(1rem + 53px);
}

.min-width-12-p {
  min-width: 12%;
}

.photo-video-item-wrapper {
  position: relative;
}

.photo-video-hover-menu-hidden {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  visibility: hidden;
}

.photo-video-hover-menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  background: #00000099;  /* 60% black */
  color: #fff;
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  transition: opacity .2s, visibility .2s;
}

.photo-video-hover-menu.active {
  visibility: visible;
  opacity: 1;
  background: #000;
}

.photo-video-item-wrapper:hover .photo-video-hover-menu {
  visibility: visible;
  opacity: 1;
}

.photo-video-item-wrapper:hover .photo-video-hover-menu:hover {
  background: #000;
}

.popup-root button {
  border: none !important;
}

@media (max-width: 768px) {
  .photo-video-item-wrapper .photo-video-hover-menu {
    visibility: visible;
    opacity: 1;
  }
}

.resume-builder-width-responsive .resume-tg .font-medium {
  font-weight: 400;
}

.resume-builder-width-responsive .resume-tg .font-medium em {
  font-weight: 700;
}

.resume-builder-width-responsive .resume-tg .template-two .members-list__member svg {
  width: 3px;
  height: 10px;
  margin-right: 0.25rem;
}

.resume-builder-width-responsive .resume-tg .template-three .header-contact-info .contact-label {
  font-size: 6px;
}

.resume-builder-width-responsive .resume-tg .template-three .header-contact-info .contact-value {
  font-weight: 300;
  font-size: 8px;
  width: 52px;
}

.resume-builder-width-responsive .resume-tg .template-four .contact-info .contact-label {
  font-weight: 400;
  font-size: 10px;
  color: #55616C;
}

.resume-builder-width-responsive .resume-tg .template-four .contact-info .contact-value {
  font-weight: 400;
  font-size: 10px;
  color: #041E36;
}

.profile-social-share-container svg {
  min-width: 45px;
  max-width: 45px;
}

/* Chrome, Safari, Edge, Opera */
input[type=number].number-to-text::-webkit-outer-spin-button,
input[type=number].number-to-text::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number].number-to-text{
    -moz-appearance: textfield;
}

.customCSS > ul{
  list-style: disc;
}
 i, em {
  font-style: italic !important;
  font-family: arial;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}


.break-anywhere {
  overflow-wrap: anywhere;
}


.tilt {
  top: unset !important;
  left: unset !important;
  right: unset !important;
  rotate: 2deg !important;
  transition: 0.1s ease-in-out !important;
  margin: auto;
}
.dragHandleSelector {
  top: auto !important;
  left: auto !important;
}
.customSelect{
  /* all: unset !important; */
  margin: 0 !important;
  padding: 0 !important;
} 

/**************** Start MultiRangeSlider************/
.container-range {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.container-range  > input[type="range"] {
  overflow:visible;
  /* height: 100%; */
  /* width: 100% !important; */
  background-color: transparent;
}

.container-range  > input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
}

.container-range  > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  background: #0c6ed1 !important;
}

/* for Firebox */
.container-range  > input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  /* background: #0c6ed1 !important; */
}

/* for IE */
.container-range  > input[type="range"]::-ms-thumb {
  cursor: pointer;
  /* background: #0c6ed1 !important; */
}


.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 10px;
}

.slider__track {
  background-color: #d5d5d5; 
  width: 100%;
  z-index: 1;
  height: 10px;
}

.slider__range {
  background-color: #0c6ed1;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #070707;
  font-size: 12px;
  /* margin-top: 20px; */
}

.slider__left-value {
  /* left: 6px; */
  top:-30px;
}

.slider__right-value {
  right: -4px;
}



/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb { 
  background-color: #f1f5f7 !important;
  border: none  !important;
  border-radius: 50%  !important;
  box-shadow: 0 0 1px 1px #ced4da  !important;
  cursor: pointer  !important;
  height: 28px  !important;
  width: 28px  !important;
  margin-top: 7px  !important;
  pointer-events: all  !important;
  position: relative  !important;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7 !important;
  border: none  !important;
  border-radius: 50%  !important;
  box-shadow: 0 0 1px 1px #ced4da  !important;
  cursor: pointer  !important;
  height: 28px  !important;
  width: 28px  !important;
  margin-top: 7px  !important;
  pointer-events: all  !important;
  position: relative  !important;
}
/****************END MultiRangeSlider************/

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  background-color: transparent !important;
  transition: background-color 99999999s ease;
}