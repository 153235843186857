.defaultButton {
  @apply relative bg-primary p-3;
}

.defaultButtonText {
  @apply text-white text-base 2md:text-lg;
}

.action-btn {
  width: 40px !important;
  height: 40px !important;
  background: #f2f2f2;
  border-radius: 6px;
  margin-right: 10px;
}

.action-btn-round {
  width: 40px !important;
  height: 40px !important;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 500px;
  margin-right: 10px;
}

.action-btn-secondary {
  width: 30px !important;
  height: 30px !important;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  @apply rounded-full mr-2;
}

.limit-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
