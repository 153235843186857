.page-wrapper {
    @apply h-full;
}

.main-page-wrapper {
    min-height: calc(100vh - 160px);
    @apply w-full grid py-6 px-4 3md:px-24 lg:px-37 xl:px-50 1xl:px-65;
}

.auth-page-wrapper {
    min-height: calc(100vh - 160px);
    @apply w-full py-6 px-4 3md:px-24 lg:px-37 xl:px-50 1xl:px-65;
}

.profile-page-wrapper {
    min-height: calc(100vh - 160px);
    @apply w-full py-6;
}
.text-passwordGray {
    margin: 0px 4px;
}

.name-header {
    display: flex;
    flex-wrap: wrap;
}

.profile-right{
    width: 420px;
}

@media (max-width: 767px) {
    .profile-right{
        width: auto;
    }
    .name-header-name {
        margin-top: 5px !important;
    }
    .name-header-username {
        margin-top: -5px !important;
    }
    .profile-page-wrapper.no-profile-found {
        min-height: calc(100vh - 194px);
    }
}

@media (max-width: 484px) {
    .share-own {
        display: flex;
        flex: 1;
        justify-content: center;
    }
    .profile-page-wrapper.no-profile-found {
        min-height: calc(100vh - 188px);
    }
}

@media (max-width: 342px) {
    .profile-page-wrapper.no-profile-found {
        min-height: calc(100vh - 206px);
    }
}

.profile-page-wrapper.no-profile-found > div {
    width: 70% !important;
}

@media (max-width: 1024px) {
    .profile-page-wrapper.no-profile-found > div {
        width: 90% !important;
    }
}

.profile-page-wrapper-padding {
    @apply w-full px-4 3md:px-24 lg:px-37 xl:px-50 1xl:px-65;
}

.profile-manage-wrapper {
    min-height: calc(100vh - 160px);
    @apply w-full py-17.5;
}

.milestone-page-wrapper {
    @apply w-full 3md:px-24 lg:px-37 xl:px-50 1xl:px-65;
}

.website-page-wrapper {
    min-height: calc(100vh - 450px);
    @apply w-full px-4 3md:px-24 lg:px-37 xl:px-50 1xl:px-65;
}

.landing-page-wrapper {
    min-height: calc(100vh - 160px);
    @apply w-full;
}

.form-page-wrapper {
    @apply relative h-screen bg-primary flex flex-col items-center justify-center;
}

.form-container {
    @apply bg-white w-full flex flex-col items-center justify-center rounded-xl pt-6 px-6 1md:pt-11 1md:px-15;
}

.form-logo {
    @apply w-24.5 h-24.5 1md:w-50 1md:h-50;
}

.login-container {
    @apply w-full my-auto grid gap-y-12 3md:grid-cols-2 3md:gap-y-0;
}

/* Column Section */
.form-column-title {
    @apply text-left text-3xl w-full font-bold tracking-tighter text-lightBlack;
}

.form-column-subtitle {
    letter-spacing: -0.02em;
    @apply w-full text-base text-lightBlack pb-8;
}

.form-column-container {
    @apply w-full;
}

.form-input-container {
    @apply flex-1 mb-6;
}

.form-input-container-bottom {
    @apply flex-1;
}

.form-input-container-notification {
    @apply flex-1 overflow-hidden p-1 mr-5;
}
.checkbox-container {
    @apply w-full;
}

.forgot-password-link {
    @apply text-xs 1xs:text-sm text-primary cursor-pointer font-semibold;
}

.no-account-text {
    @apply text-center font-light text-xs
        2md:text-sm
        2xl:text-lg
        3xl:text-lg;
}

.no-account-link {
    @apply text-primary cursor-pointer;
}

.checkbox-text {
    @apply text-xs ml-3 mt-1 font-medium 2md:text-sm;
}

.page-link {
    @apply px-2.5 py-1 bg-white
        border-2 rounded-lg border-gray
        text-sm font-bold opacity-70 cursor-pointer
        hover:bg-gray hover:border-black;
}

.page-link.active {
    @apply bg-gray border-black;
}

.page-view-graph {
    transform: none !important;
}

.image-zoom-mobile {
    display: none !important;
}

/* see more */
.html-wrapper {
    /* display: flex;
    align-items: flex-end; */
    position: relative;
}
.html-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease;
    /* color: #4d4d4d; */
    color: #080809
}

div.html-content a {
    text-decoration: underline;
    color: #06c;
  }

.collapsed {
    -webkit-line-clamp: 2; /* Limits to 2 lines */
}

.expanded {
    -webkit-line-clamp: unset; /* Removes the limit */
}

.html-wrapper button {
    color: #0c6ed1;
    /* font-weight: 800; */
}
/* end see more */

.profile-occupations {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 380px !important;
}

@media (max-width: 668px) {
    .image-zoom {
        display: none !important;
    }
    .image-zoom-mobile {
        display: inline !important;
    }
}

@media (max-width: 480px) {
    .alert{
        padding: 12px 22px;
    }
    .profile-occupations {
        width: 225px !important;
    }

    .text-verified {
        font-size: 12px;
        font-weight: 400;
    }
    .alerts svg {
        width: 20px;
        height: 20px;
    }
    .alerts .close-alert {
        width: 10px;
        height: 10px;
    }

    .milestone-location {
        display: block !important;
    }

    .image-zoom {
        display: none !important;
    }
    .image-zoom-mobile {
        display: inline !important;
    }
    .image-zoom-mobile-image {
        width: 480px !important;
    }

    .modal-gallery {
        width: 100vw;
        padding: 10px;
    }

    .modal-gallery .text-title {
        font-size: 14px;
    }

    .modal-gallery .text-counter {
        font-size: 14px;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 10px;
    }

    .slick-prev {
        display: block;
        transform: scale(2.4);
        z-index: 999999999999;
        left: 20px !important;
        margin-left: 0px !important;
    }

    .slick-next {
        display: block;
        transform: scale(2.4);
        z-index: 999999999999;
        right: 0px !important;
        margin-right: 0px !important;
    }

    .other-description {
        margin-top: 0px !important;
    }
}

@media (max-width: 1164px) {
    .other-description {
        margin-top: -19px;
    }
}

.audio-container {
    position: sticky;
    bottom: 0px;
    left: 0px;
    animation: fade-in 2s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

.other-description {
    display: flex;
    padding-left: 59px;
    padding-right: 35px;
    position: relative;
}

.other-description-line {
    position: absolute;
    left: 16px;
    margin-top: -17px;
}

.other-description-input {
    flex: 1;
}

.img-gallery .slick-list, .img-gallery .slick-list .slick-track{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
}
